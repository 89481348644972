'use client'

import {
  Button,
  Loader,
  NativeSelect,
  NumberInput,
  TextInput,
} from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import clsx from 'clsx'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useFormState, useFormStatus } from 'react-dom'
import { addMetrics } from '../companyDetailActions'

function SubmitButton() {
  const { pending } = useFormStatus()

  return (
    <Button
      color="rgb(0, 42, 58)"
      type="submit"
      disabled={pending}
      classNames={{
        label: 'flex gap-2',
      }}
    >
      Save{' '}
      {pending ? <Loader color="rgb(0, 42, 58)" type="dots" size={14} /> : ''}
    </Button>
  )
}

export default function AddMetricModal({
  context,
  id,
  innerProps: { companyOrgId },
}: ContextModalProps<{
  companyOrgId: string
}>) {
  const [{ done, success, message, title, errors }, formAction] = useFormState(
    addMetrics,
    {
      done: false,
      success: false,
      message: '',
      title: '',
      errors: {},
    }
  )
  const [shownToast, setShownToast] = useState(false)

  useEffect(() => {
    if (done) {
      if (success) {
        context.closeModal(id)
      }

      if (!shownToast) {
        notifications.show({
          title: title,
          message: message,
          color: success ? 'green' : 'red',
        })

        setShownToast(true)
      }
    }
  }, [context, done, id, message, shownToast, success, title])

  return (
    <form action={formAction}>
      <input type="hidden" name="companyOrgId" value={companyOrgId} />

      <div className="flex flex-row w-1/2 gap-4">
        <NativeSelect
          name="currencyCode"
          label="Currency"
          defaultValue={'USD'}
          data={['USD']}
          required
          error={errors.currencyCode}
        />

        <div>
          <label htmlFor="meetingDate" className="text-sm font-medium">
            Meeting Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            name="meetingDate"
            id="meetingDate"
            defaultValue={format(new Date(), 'yyyy-MM-dd')}
            className={clsx(
              'w-full px-3 h-9 rounded-[4px] text-sm mb-[5px] border border-[#ced4da] focus:outline outline-blue-400 text-black',
              {
                'border-red-500': errors.meetingDate,
              }
            )}
          />
          <p className="text-xs text-red-500">{errors.meetingDate}</p>
        </div>
      </div>

      <hr className="my-4" />

      <div className="flex flex-row gap-4">
        <NativeSelect
          name="periodType"
          label="Period Type"
          defaultValue={'Q'}
          data={['Q']}
          required
          error={errors.periodType}
        />

        <div>
          <label htmlFor="dueDate" className="text-sm font-medium">
            Period End
          </label>
          <input
            type="date"
            name="periodEnd"
            id="periodEnd"
            className={clsx(
              'w-full px-3 h-9 rounded-[4px] text-sm mb-[5px] border border-[#ced4da] focus:outline outline-blue-400 text-black',
              {
                'border-red-500': errors.periodEnd,
              }
            )}
          />
          <p className="text-xs text-red-500">{errors.periodEnd}</p>
        </div>

        <NativeSelect
          name="metricName"
          label="Metric"
          defaultValue={'ARR'}
          data={['ARR']}
          required
          error={errors.metricName}
        />

        <NumberInput
          label="Metric Value"
          name="metricValue"
          required
          error={errors.metricValue}
        />

        <TextInput name="note" label="Note" error={errors.note} />
      </div>

      <div className="flex gap-2 mt-4 justify-end">
        <Button variant="default" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <SubmitButton />
      </div>
    </form>
  )
}
