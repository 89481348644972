'use client'

import { updateTask } from '@/app/(root)/pipeline-calendar/actions'
import { Company, UserTaskEvent } from '@/types'
import formatAddress from '@/utils/formatAddress'
import formatDate from '@/utils/formatDate'
import {
  ArrowTopRightOnSquareIcon,
  EyeIcon,
  PencilIcon,
} from '@heroicons/react/24/solid'
import {
  Button,
  Loader,
  NativeSelect,
  Textarea,
  TextInput,
} from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import clsx from 'clsx'
import { add, format, parse, startOfDay } from 'date-fns'
import Link from 'next/link'
import { useState, useTransition } from 'react'
import { useFormStatus } from 'react-dom'
import ExternalLink from './ExternalLink'

const SubmitButton = () => {
  const { pending } = useFormStatus()

  return (
    <Button
      color="rgb(0, 42, 58)"
      type="submit"
      w="100%"
      disabled={pending}
      className="flex gap-2 justify-center mt-4"
      rightSection={
        pending && <Loader color="rgb(0, 42, 58)" type="dots" size={14} />
      }
    >
      Update
    </Button>
  )
}

export default function ViewTaskModal({
  context,
  id,
  innerProps: { task, dispatch },
}: ContextModalProps<{
  task: UserTaskEvent
  dispatch: (action: {
    action: string
    newTask?: UserTaskEvent | undefined
  }) => void
}>) {
  const [isEditing, setIsEditing] = useState(false)
  const [dueDate, setDueDate] = useState(task.activityDate)
  const [, startTransition] = useTransition()
  const [{ errors }, setFormState] = useState<{
    done: boolean
    success: boolean
    title: string
    message: string
    errors: {
      id?: string[] | undefined
      companySalesforceId?: string[] | undefined
      subject?: string[] | undefined
      status?: string[] | undefined
      dueDate?: string[] | undefined
      description?: string[] | undefined
    }
  }>({
    errors: {
      id: undefined,
      companySalesforceId: undefined,
      subject: undefined,
      status: undefined,
      dueDate: undefined,
      description: undefined,
    },
    done: false,
    success: false,
    title: '',
    message: '',
  })

  const handleSubmit = async (formData: FormData) => {
    const formValues = Object.fromEntries(formData.entries()) as {
      id: string
      companySalesforceId: string
      subject: string
      status: string
      dueDate: string
      description: string
    }

    startTransition(() => {
      dispatch({
        action: 'modify',
        newTask: {
          ...task,
          subject: formValues.subject,
          status: formValues.status,
          activityDate: formValues.dueDate,
          description: formValues.description,
          title: '',
          start: parse(formValues.dueDate, 'yyyy-MM-dd', new Date()),
          end: startOfDay(
            add(parse(formValues.dueDate, 'yyyy-MM-dd', new Date()), {
              days: 1,
            })
          ),
          allDay: true,
        },
      })
    })

    const result = await updateTask(formData)
    setFormState(result)

    if (!result.success) {
      notifications.show({
        title: result.title,
        message: result.message,
        color: 'red',
      })
      return
    }

    context.closeModal(id)

    notifications.show({
      title: 'Updated Task',
      message: '',
      color: 'green',
    })
  }

  return (
    <div className="flex flex-col gap-2">
      <Link
        href={`/company/${task.companyOrgId}`}
        className="flex gap-4 bg-battery-triangle p-4 text-white rounded-md"
        onClick={() => context.closeModal(id)}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={task.logoUrl || 'https://placehold.co/50x50'}
          alt={`${name} logo`}
          width={50}
          height={50}
          className="h-[50px] object-contain"
        />
        <div className="flex flex-col justify-center">
          <span className="inline-block">{task.name}</span>
          <span>
            {formatAddress({
              city: task.city,
              state: task.state,
              country: task.country,
            } as Company)}
          </span>
        </div>
      </Link>

      <div className="flex items-center gap-4">
        <button
          className="hover:cursor-pointer rounded p-2 hover:bg-gray-200"
          onClick={() => setIsEditing(!isEditing)}
        >
          {!isEditing && (
            <PencilIcon className="h-4 w-4 text-battery-triangle" />
          )}
          {isEditing && <EyeIcon className="h-4 w-4 text-battery-triangle" />}
        </button>

        {!isEditing && (
          <div>
            <h1 className="text-xl font-semibold flex gap-2">
              {task.subject}{' '}
              <ExternalLink
                href={`https://batteryventures.my.salesforce.com/${task.id}`}
                className="text-xl font-bold flex items-center"
              >
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </ExternalLink>
            </h1>
            <p>Due: {formatDate(task.activityDate)}</p>
            <p>Status: {task.status}</p>
            {task.description && (
              <div>
                <p>Description:</p>
                <pre className="break-words whitespace-pre-wrap font-sans">
                  {task.description}
                </pre>
              </div>
            )}
          </div>
        )}

        {isEditing && (
          <form
            className="flex flex-col gap-3 w-full"
            noValidate
            action={handleSubmit}
          >
            <input readOnly hidden name="id" value={task.id} />
            <input
              readOnly
              hidden
              name="companySalesforceId"
              value={task.companySalesforceId}
            />

            <TextInput
              name="subject"
              label="Subject"
              required
              defaultValue={task.subject}
              error={errors.subject}
            />

            <NativeSelect
              name="status"
              label="Status"
              defaultValue={task.status}
              data={[
                'Not Started',
                'In Progress',
                'Completed',
                'Waiting on someone else',
                'Deferred',
                'Touch',
              ]}
              required
              error={errors.status}
            />

            <div className="w-full">
              <label htmlFor="dueDate" className="text-sm font-medium">
                Due Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                name="dueDate"
                id="dueDate"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                className={clsx(
                  'w-full px-3 h-9 rounded-[4px] text-sm mb-[5px] border border-[#ced4da] focus:outline outline-blue-400 text-black',
                  {
                    'border-red-500': errors.dueDate,
                  }
                )}
              />
              <p className="text-xs text-red-500">{errors.dueDate}</p>
              <div className="px-3 flex gap-2">
                <p className="text-xs">Add Days:</p>
                <button
                  className="text-xs underline underline-offset-2"
                  type="button"
                  onClick={() => setDueDate(task.activityDate)}
                >
                  Reset
                </button>
                <button
                  className="text-xs underline underline-offset-2"
                  type="button"
                  onClick={() =>
                    setDueDate(
                      format(add(new Date(), { days: 15 }), 'yyyy-MM-dd')
                    )
                  }
                >
                  +15
                </button>
                <button
                  className="text-xs underline underline-offset-2"
                  type="button"
                  onClick={() =>
                    setDueDate(
                      format(add(new Date(), { days: 30 }), 'yyyy-MM-dd')
                    )
                  }
                >
                  +30
                </button>
                <button
                  className="text-xs underline underline-offset-2"
                  type="button"
                  onClick={() =>
                    setDueDate(
                      format(add(new Date(), { days: 90 }), 'yyyy-MM-dd')
                    )
                  }
                >
                  +90
                </button>
              </div>
            </div>

            <Textarea
              name="description"
              label="Description"
              autosize
              minRows={3}
              defaultValue={task.description}
              error={errors.description}
            />

            <SubmitButton />
          </form>
        )}
      </div>
    </div>
  )
}
