'use client'

import classes from '@/components/autoComplete.module.css'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import {
  CloseButton,
  Combobox,
  Loader,
  TextInput,
  useCombobox,
} from '@mantine/core'
import { useDebounce } from '@uidotdev/usehooks'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

export default function AutoComplete<T>({
  className,
  preformSearch,
  onSubmit,
  onClear = () => {},
  autoFocus = false,
  inputLabel = '',
  inputPlaceholder,
  defaultValue = '',
  keyAccessor,
  valueAccessor,
  labelAccessor,
  variant,
  withAsterisk,
  error,
}: {
  className?: string
  preformSearch: (searchQuery: string) => Promise<T[]>
  onSubmit: (label: string, value: string) => void
  onClear?: () => void
  autoFocus?: boolean
  inputLabel?: string
  inputPlaceholder: string
  defaultValue?: string
  keyAccessor: string
  valueAccessor: string
  labelAccessor: string
  variant?: 'search'
  withAsterisk?: boolean
  error?: React.ReactNode
}) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T[] | null>(null)
  const [empty, setEmpty] = useState(false)

  const [searchQuery, setSearchQuery] = useState('')
  const [internalDefaultValue, setInternalDefaultValue] = useState(defaultValue)
  const debouncedSearchQuery = useDebounce(searchQuery, 200)

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const clearInput = () => {
    setSearchQuery('')
    setInternalDefaultValue('')
    setData([])
    setEmpty(true)
    combobox.openDropdown()
    combobox.focusTarget()
    onClear()
  }

  useEffect(() => {
    if (debouncedSearchQuery) {
      setLoading(true)

      preformSearch(debouncedSearchQuery)
        .then((result) => {
          setData(result)
          setLoading(false)
          setEmpty(result.length === 0)
        })
        .finally(() => setLoading(false))
    }
  }, [debouncedSearchQuery, preformSearch])

  const isSearchInput = variant === 'search'
  const closeButtonStyles = isSearchInput
    ? {
        root: {
          color: '#c1d2dc',
        },
      }
    : {}
  const textInputStyles = isSearchInput
    ? {
        input: {
          backgroundColor: '#67828d',
          color: 'white',
        },
      }
    : {}
  const dropdownStyles = isSearchInput
    ? {
        dropdown: {
          backgroundColor: '#67828d',
          color: 'white',
          borderColor: '#c1d2dc',
        },
      }
    : {
        dropdown: {
          backgroundColor: 'rgb(0, 42, 58)',
          color: 'white',
        },
      }
  const textInputClassNames = isSearchInput ? classes.input : ''
  const loaderColor = isSearchInput ? '#c1d2dc' : undefined

  let rightSection = null
  let leftSection = null

  if (isSearchInput) {
    leftSection = <MagnifyingGlassIcon className="h-5 w-5 text-[#c1d2dc]" />
  }

  if (searchQuery !== '' || internalDefaultValue !== '') {
    rightSection = (
      <CloseButton
        styles={closeButtonStyles}
        variant={variant === 'search' ? 'transparent' : 'subtle'}
        size="sm"
        onMouseDown={(event) => event.preventDefault()}
        onClick={clearInput}
        aria-label="Clear value"
      />
    )
  }

  if (loading) {
    rightSection = <Loader size={18} color={loaderColor} />
  }

  return (
    <Combobox
      onOptionSubmit={(optionValue, optionProps) => {
        setSearchQuery(String(optionProps?.children || ''))
        onSubmit(String(optionProps?.children || ''), optionValue)
        combobox.closeDropdown()
        if (isSearchInput) {
          clearInput()
        }
      }}
      styles={dropdownStyles}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          className={className}
          styles={textInputStyles}
          classNames={{
            input: textInputClassNames,
          }}
          autoFocus={autoFocus}
          label={inputLabel}
          placeholder={inputPlaceholder}
          value={searchQuery || internalDefaultValue}
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          onClick={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          onFocus={() => combobox.openDropdown()}
          rightSection={rightSection}
          leftSection={leftSection}
          withAsterisk={withAsterisk}
          error={error}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {(data || []).map((data) => (
            <Combobox.Option
              value={(data as any)[valueAccessor]}
              key={(data as any)[keyAccessor]}
              className={clsx('font-semibold', {
                'hover:bg-[#c1d2dc7a] data-[combobox-selected=true]:bg-[#c1d2dc7a]':
                  isSearchInput,
                'hover:bg-battery-triangle data-[combobox-selected=true]:bg-battery-triangle':
                  !isSearchInput,
              })}
            >
              {(data as any)[labelAccessor]}
            </Combobox.Option>
          ))}
          {empty && (
            <Combobox.Empty>
              <p className="font-semibold text-white">No results found</p>
            </Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
