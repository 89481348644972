'use client'

import Card from '@/components/Card'
import { signIn } from 'next-auth/react'
import Image from 'next/image'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

export default function SignInRedirect() {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()!
  const isForcedLogout = Boolean(searchParams.get('forceLogout'))

  useEffect(() => {
    if (!isForcedLogout) {
      void signIn('okta')
    }
  }, [isForcedLogout, searchParams])

  if (isForcedLogout) {
    return (
      <main className="flex h-full">
        <Card className="rounded-sm border-neutral-50 m-auto w-[25rem] p-0">
          <div className="bg-navbar flex items-center gap-4 p-4 justify-center rounded-sm">
            <Image
              className=""
              src="battery-logo-white.svg"
              alt="battery"
              width={120}
              height={40}
            />
            <h1 className="text-white tracking-wide text-lg">
              Sign in to Charger
            </h1>
          </div>
          <div className="flex my-8 justify-center">
            <button
              className="tracker-wide inline-flex justify-center rounded-md border border-transparent bg-navbar px-4 py-2 text-sm font-medium text-white"
              onClick={() => router.push(pathname)}
            >
              Login with Okta
            </button>
          </div>
        </Card>
      </main>
    )
  }

  return (
    <main>
      <h1 className="text-2xl font-bold tracking-wide">Redirecting...</h1>
    </main>
  )
}
