'use client'

import { Session } from 'next-auth'
import { createContext } from 'react'

export const AppContext = createContext<{
  appEnv: string
  user?: Session['user']
  avatarSrc: string
  isAdmin: boolean
  impersonateSalesforceId: string | null
  impersonateName: string | null
}>({
  appEnv: 'production',
  user: undefined,
  avatarSrc: '',
  isAdmin: false,
  impersonateSalesforceId: null,
  impersonateName: null,
})

export default function AppProvider({
  appEnv,
  user,
  avatarSrc,
  isAdmin,
  impersonateSalesforceId,
  impersonateName,
  children,
}: {
  appEnv: string
  user?: Session['user']
  avatarSrc: string
  isAdmin: boolean
  impersonateSalesforceId: string | null
  impersonateName: string | null
  children: React.ReactNode
}) {
  return (
    <AppContext.Provider
      value={{
        appEnv,
        user,
        avatarSrc,
        isAdmin,
        impersonateSalesforceId,
        impersonateName,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
