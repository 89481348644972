'use client'

import { getLatestNote } from '@/app/(root)/dashboardActions'
import formatDate from '@/utils/formatDate'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { Loader } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import { useEffect, useState } from 'react'
import ExternalLink from './ExternalLink'

export default function ViewLatestNoteModal({
  innerProps: { companySalesforceId },
}: ContextModalProps<{ companySalesforceId: string }>) {
  const [loading, setLoading] = useState(false)
  const [task, setTask] = useState<{
    Id: string
    Description: string
    Subject: string
    CreatedDate: string
  } | null>(null)

  useEffect(() => {
    async function getTask() {
      setLoading(true)
      const task = await getLatestNote(companySalesforceId)
      setLoading(false)
      setTask(task)
    }

    getTask()
  }, [companySalesforceId])

  if (loading) {
    return (
      <div className="flex justify-center">
        <Loader color="rgb(0, 42, 58)" type="dots" size={16} />
      </div>
    )
  }

  if (!task) {
    return (
      <div>
        <p>No note found.</p>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h1 className="text-xl font-semibold flex gap-2">
          {task.Subject}{' '}
          <ExternalLink
            href={`https://batteryventures.my.salesforce.com/${task.Id}`}
            className="text-xl font-bold flex items-center"
          >
            <ArrowTopRightOnSquareIcon className="h-4 w-4" />
          </ExternalLink>
        </h1>
        <p>{formatDate(task.CreatedDate)}</p>
      </div>
      <pre className="break-words whitespace-pre-wrap font-sans">
        {task.Description}
      </pre>
    </div>
  )
}
