import { Company } from '@/types'
import { COUNTRY_NAME_MAP, STATE_NAME_MAP } from '@/types/constants'

export default function formatAddress({ city, state, country }: Company) {
  const abbrState = STATE_NAME_MAP[String(state).toLowerCase()] || state
  const abbrCountry = COUNTRY_NAME_MAP[String(country).toLowerCase()] || country

  if (city && state && country) {
    return `${city}, ${abbrState}, ${abbrCountry}`
  }

  if (city && country) {
    return `${city}, ${abbrCountry}`
  }

  return ''
}
