import { forwardRef } from 'react'

export default forwardRef<
  HTMLAnchorElement | null,
  {
    href: string
    className?: string
    children: React.ReactNode
    [x: string]: any
  }
>(function ExternalLink({ href, className, children, ...rest }, ref) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener"
      className={className}
      ref={ref}
      {...rest}
    >
      {children}
    </a>
  )
})
