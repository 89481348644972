'use client'

import { ContextModalProps } from '@mantine/modals'

export default function ViewNoteModal({
  innerProps: { description },
}: ContextModalProps<{ description: string }>) {
  return (
    <pre className="break-words whitespace-pre-wrap font-sans">
      {description}
    </pre>
  )
}
