import { TEAMS } from '@/types/constants'

export default function isOnAnyTeam(userSalesforceId?: string | null) {
  if (
    userSalesforceId === '' ||
    userSalesforceId?.toLowerCase() === 'null' ||
    !userSalesforceId
  ) {
    return false
  }

  for (const team of TEAMS) {
    for (const member of team.members) {
      if (member.salesforceUserId === userSalesforceId) {
        return true
      }
    }
  }

  return false
}
