import { twMerge } from 'tailwind-merge'

export default function Card({
  children,
  className,
}: {
  className?: string
  children: React.ReactNode
}) {
  return (
    <div
      className={twMerge(
        'border border-neutral-200 rounded-md drop-shadow-lg bg-white p-4',
        className
      )}
    >
      {children}
    </div>
  )
}
